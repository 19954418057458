<template>
  <v-row
    v-if="file.order_id === currentOrderId"
    no-gutters
    :class="['my-8']"
  >
    <v-subheader
      class="text--text darken-3 font-weight-bold subtitle-1 pl-0"
    >
      {{ num }}. {{ file.name }}
    </v-subheader>

    <v-row
      no-gutters
      class="col-12 ma-0 mt-3 border wrapper d-none d-md-block box-shadow border-radius-5"
    >
      <v-col
        class="pa-0 nested-item"
      >
        <iframe
          :src="file.view_url"
          style="width: 100%; height: 100%;"
        >
          Ваш браузер не підтримує формат відображення
        </iframe>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="col-12 ma-0 mt-3 pl-sm-5 py-3 d-md-none"
    >
      <v-btn
        rounded
        color="success"
        class="col-12 col-sm-auto"
        target="_blank"
        @click="downloadHandler(file.file_token)"
      >
        Відкрити файл
        <span class="d-none d-sm-inline">
          в новому вікні
        </span>
      </v-btn>
      <v-btn
        rounded
        color="success"
        class="mt-7 mt-sm-0 ml-sm-7 ml-lg-10 col-12 col-sm-auto"
        download
        @click="downloadHandler(file.file_token)"
      >
        Скачати файл
      </v-btn>
    </v-row>

    <v-row class="ma-0 col-12 mt-7 mb-1 mt-lg-8 mb-lg-2">
      <p>
        Електронний підпис одноразовим ідентифікатором
      </p>
    </v-row>

    <v-row class="ma-0 col-12 justify-space-between">
      <p class="ma-0">
        {{ date | formatDate }}
      </p>
      <v-col
        class="pa-0 d-flex justify-space-between col-12 col-sm-7 col-md-6 mt-3 mt-sm-0
        flex-wrap pr-md-16">
        <p class="ma-0 mb-2 mr-2">
          {{ fullName }}
        </p>
        <p class="ma-0 font-weight-bold">
          {{ signature }}
        </p>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import downloadDocServiceMixin from '../../mixins/downloadDocServiceMixin';

export default {
  name: 'AuxiliaryView',
  filters: {
    formatDate: (d) => d.toLocaleString('ru-RU').replace(',', '').slice(0, -3),
  },
  mixins: [downloadDocServiceMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    // index: Number,
    file: Object,
    num: Number,
    fullName: String,
    currentOrderId: String,
  },
  data: () => ({
    date: new Date(),
    signature: '',
  }),
  created() {
    // eslint-disable-next-line no-return-assign
    this.intervalId = setInterval(() => this.date = new Date(), 1000);
  },
  beforeMount() {
    this.signature = this.$route.query.signature;
  },
  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    downloadHandler(token, download) {
      if (download === false) {
        this.downloadDoc(token, false);
      } else {
        this.downloadDoc(token);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .border {
    border: 1.5px solid #000;
  }

  .wrapper {
    overflow-y: hidden;

    @media screen and (min-width: 1264px) {
      scrollbar-width: thin;
      scrollbar-color: #6cb365 rgba(0, 0, 0, 0.2);

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6cb365;
        outline: 1px solid slategrey;
      }
    }
  }

  .nested-item {
    height: 500px;
  }
</style>
