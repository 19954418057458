<template>
  <v-scale-transition mode="in-out">
    <div
      v-if="isAuthenticated"
      class="border-radius-10 border-green box-shadow px-2 py-5 px-sm-5 pa-md-10 px-lg-15"
    >
      <notification
        v-if="errorNotification"
        type="error"
      >
        Щось пішло не так. Спробуйте ще раз
      </notification>

      <notification
        v-if="successNotification"
        type="success"
      >
        Договір успішно підписано!
      </notification>

      <template v-if="attachedFiles.length > 0">
        <v-subheader
          class="col-12 text--text darken-3 subtitle-1 text-justify mt-3 mb-3 height-auto
          d-flex flex-column align-start pa-0"
        >
          <p class="text-h6 mb-1 col-12 pa-0 text-center text-sm-start">
            Підписання договору
          </p>
          <p>
            <!--          <span
                        v-if="firstName"
                      >{{ firstName }}</span>,-->
            Ознайомтесь з договором та натисніть кнопку
            <span class="font-weight-bold">Підписати</span>
            для підтвердження
          </p>
        </v-subheader>

        <auxiliary-view
          v-for="(file, index) of attachedFiles"
          :key="index"
          :num="index + 1"
          :file="file"
          :full-name="fullName"
          :current-order-id="currentOrderId"
        >
        </auxiliary-view>

        <validation-observer ref="observer">
          <v-row
            no-gutters
            class="col-12 position-relative"
          >
<!--            <validation-provider-->
<!--              v-slot="{ errors, validated }"-->
<!--              rules="checkboxRequired"-->
<!--            >-->
<!--              <v-checkbox-->
<!--                v-model="loanPassportConfirmation"-->
<!--                label="Ознайомлений та підписую паспорт кредиту"-->
<!--                color="success darken-2"-->
<!--                class="mt-7 col-12 dark-checkbox align-center pa-0"-->
<!--                :error-messages="errors"-->
<!--                :error="!loanPassportConfirmation && validated"-->
<!--                :success="loanPassportConfirmation && validated"-->
<!--                hide-details-->
<!--                :dark="$vuetify.theme.dark"-->
<!--                @change="markPassportOfLoan"-->
<!--              />-->
<!--              <span class="error-msg error&#45;&#45;text v-messages pl-8 position-absolute">-->
<!--                {{ errors[0] }}-->
<!--              </span>-->
<!--            </validation-provider>-->

            <v-row
              no-gutters
              class="col-12 justify-center justify-md-space-between pr-md-16 mt-4 mt-md-5"
              align-content="center"
            >
              <validation-provider
                v-slot="{ errors, validated }"
                rules="checkboxRequired"
                class="col-12 col-md-auto "
              >
                <v-checkbox
                  v-model="confirmationOfLoanAgreement"
                  label="Ознайомлений та підписую кредитний договір"
                  color="success darken-2"
                  class="mt-1 dark-checkbox align-center pa-0"
                  :error-messages="errors"
                  :error="!confirmationOfLoanAgreement && validated"
                  :success="confirmationOfLoanAgreement && validated"
                  hide-details
                  :dark="$vuetify.theme.dark"
                />
                <span class="error-msg error--text v-messages pl-8 position-absolute">
                  {{ errors[0] }}
                </span>
              </validation-provider>

              <div class="position-relative">
                <block-loader
                  v-if="blockPreloaderIsVisible"
                  background-color="background"
                ></block-loader>

                <v-btn
                  :disabled="!buttonIsActive"
                  rounded
                  color="success"
                  class="mt-10 mt-md-0"
                  @click="submit"
                >
                  Підписати
                </v-btn>
              </div>
            </v-row>
          </v-row>
        </validation-observer>
      </template>

      <div
        v-else-if="attachedFiles.length <= 0 && attachedFilesAreUploaded"
        class="d-flex flex-column align-center justify-center mx-auto"
      >
        <v-subheader
          class="col-12 text--text darken-3 text-h6 mt-3 mb-3 d-flex justify-center text-center
                 text-md-h5"
        >
          Договір для цієї заявки відсутній
        </v-subheader>

        <v-btn
          v-if="isAuthenticated"
          rounded
          color="success"
          class="mt-3"
          :to="{ name: 'UserCredits' }"
        >
          На головну
        </v-btn>
      </div>
    </div>
  </v-scale-transition>
</template>

<script>
import AuxiliaryView from '@/views/contract-signing/AuxiliaryView';
import { mapActions, mapGetters } from 'vuex';
import BlockLoader from '@/components/common/BlockPreloader';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import workWithProductsMixin from '@/mixins/getProductByCode';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../../mixins/pageLoaderMixin';
import buttonDisablerMixin from '../../mixins/buttonDisablerMixin';

export default {
  name: 'Index',
  title: 'Підписання договору',
  components: {
    Notification,
    BlockLoader,
    AuxiliaryView,
  },
  mixins: [blockPreloaderMixin, errorNotificationMixin, successNotificationMixin,
    workWithProductsMixin, pageLoaderMixin, buttonDisablerMixin],
  props: ['currentOrderId'],
  data() {
    return {
      currentScope: 'signing_contract',
      // creditPassportIsMarked: false,
      attachedFilesAreUploaded: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    loanPassportConfirmation: {
      get() {
        return this.$store.state.cashForm.signing_contract.loan_passport_confirmation;
      },

      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'loan_passport_confirmation',
          value,
        });
      },
    },
    confirmationOfLoanAgreement: {
      get() {
        return this.$store.state.cashForm.signing_contract.confirmation_of_loan_agreement;
      },

      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'confirmation_of_loan_agreement',
          value,
        });
      },
    },
    fullName() {
      return this.$store.state.clientInfo.clientData.full_name;
    },
    firstName() {
      return this.$store.state.clientInfo.clientData.first_name;
    },
    attachedFiles: {
      get() {
        return this.$store.state.attachedFiles;
      },

      set(value) {
        this.$store.commit('UPDATE_STATE', {
          key: 'attachedFiles',
          value,
        });
      },
    },
  },
  beforeMount() {
    this.attachedFiles = [];
    this.getReferenceBookStore('DICT_ATTACHED_FILE_TYPE');
    this.getClientMainInfo()
      .then(() => {
        if (!this.currentOrderId) {
          this.hidePagePreloader();
        }
      })
      .catch((err) => console.error(err));
    if (this.currentOrderId) {
      this.getAttachedFilesStore(this.currentOrderId)
        .then(() => {
          this.attachedFilesAreUploaded = true;
          this.hidePagePreloader();
        })
        .catch((err) => console.error(err));
    }
  },
  beforeDestroy() {
    this.attachedFiles = [];
  },
  methods: {
    ...mapActions(['getReferenceBookStore']),
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),
    ...mapActions('cashForm', [
      'getActiveOrderStore',
      'cashConfirmationStore',
    ]),
    ...mapActions([
      'getAttachedFilesStore',
    ]),
    goToUserCredits(time = 3000) {
      const timeout = setTimeout(() => {
        this.$router.push({ name: RouteNamesService.userCredits });
        clearTimeout(timeout);
      }, time);
    },
    cashConfirmation() {
      this.cashConfirmationStore(this.currentOrderId)
        .then((result) => {
          this.toggleBlockPreloader(false);
          if (result) {
            this.toggleSuccessMessage();
            this.makeTheButtonInactive();
            this.goToUserCredits();
          } else {
            this.toggleErrorMessage();
          }
        })
        .catch(() => {
          this.toggleBlockPreloader(false);
          this.toggleErrorMessage();
        });
    },
    submit() {
      this.$refs.observer.validate()
        .then((isValid) => {
          if (isValid) {
            this.toggleBlockPreloader(true);
            if (this.currentOrderId) {
              this.cashConfirmation();
            } else {
              this.getActiveOrderStore()
                .then((data) => {
                  const cashOnlineObj = this.getProductByCode(data);
                  if (cashOnlineObj) {
                    this.cashConfirmation();
                  } else {
                    this.toggleBlockPreloader(false);
                    this.toggleErrorMessage();
                  }
                }).catch((err) => console.error(err));
            }
          }
        });
    },
    markPassportOfLoan(e) {
      this.creditPassportIsMarked = e;
      if (!e) {
        this.confirmationOfLoanAgreement = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
